import { alpha, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Stack } from "@mui/system";

import FormSearchCompact from "./FormSearchCompact";
import { Props } from "./Props";
import { FormSearchSubmitButton } from "./FormSearchSubmitButton";
import { SearchGuestsInputForSearch } from "../../widgets/SearchGuestsInput/SearchGuestsInputForSearch";
// import FormSearchHotelPicker from "./FormSearchHotelPicker";
import { FormSearchDatePickerV2 } from "./widgets/FormSearchDatePickerV2";
import FormSearchCityPicker from "./FormSearchCityPicker";
import { useEmbeddedContext } from "../../EmbeddedContext";
import { atbStyles } from "../../../utils/constants";
import { ThemeMode } from "../../../fe-ui/theme/themeMode";

export const FormSearch = (props: Props) => {
  const { cityIdContext, hotelIdContext, type, isSheet, navigateToSearchPage, onSubmit, hasShadow } = props;
  const isCompact = type === "compact";

  const theme = useTheme();
  const { themeMode } = useEmbeddedContext();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isInSeayaMainPageDesktopHeader = !isCompact && !isSheet && !isMobile && themeMode !== ThemeMode.aztravel;
  const isInAtbMainPageDesktopHeader = !isCompact && !isSheet && !isMobile && themeMode === ThemeMode.aztravel;

  if (isCompact) {
    return <FormSearchCompact {...props} />;
  }

  return (
    <>
      <Stack
        display="flex"
        flexDirection={isMobile ? "column" : "row"}
        justifyContent="center"
        gap={isSheet ? "16px" : isMobile ? "4px" : undefined}
        alignItems={"center"}
        sx={{
          borderRadius:
            !isCompact && !isSheet
              ? themeMode === ThemeMode.aztravel
                ? atbStyles.searchForm.borderRadius
                : "16px"
              : undefined,
          boxShadow:
            hasShadow && !isMobile
              ? `0 0 2px 0 ${alpha(theme.palette.grey[500], 0.2)},
                0 12px 24px -4px ${alpha(theme.palette.grey[500], 0.12)}`
              : undefined,
        }}
      >
        {/* TODO: decide how cityIdContext value will behave */}
        {!cityIdContext && !hotelIdContext && (
          <>
            <Stack
              sx={{
                flex: 1,
                flexBasis: isInSeayaMainPageDesktopHeader
                  ? "300px"
                  : isInAtbMainPageDesktopHeader
                  ? "200px"
                  : undefined,
                width: isMobile ? "100%" : undefined,
              }}
            >
              <FormSearchCityPicker isSheet={isSheet} cities={undefined} />
            </Stack>
          </>
        )}

        <Stack
          sx={{
            flex: 1,
            flexBasis: isInSeayaMainPageDesktopHeader ? "300px" : isInAtbMainPageDesktopHeader ? "200px" : undefined,
            width: isMobile ? "100%" : undefined,
          }}
        >
          <FormSearchDatePickerV2
            context="search"
            isSheet={isSheet}
            isFirstItem={!!cityIdContext || !!hotelIdContext}
            dateFormat="full"
          />
        </Stack>

        <Stack
          sx={{
            flex: 1,
            flexBasis: isInSeayaMainPageDesktopHeader ? "180px" : isInAtbMainPageDesktopHeader ? "120px" : undefined,
            width: isMobile ? "100%" : undefined,
          }}
        >
          <SearchGuestsInputForSearch context="search" isSheet={isSheet} />
        </Stack>

        <Stack
          sx={{
            flex: 0,
            flexBasis: isInSeayaMainPageDesktopHeader ? "150px" : isInAtbMainPageDesktopHeader ? "180px" : undefined,
            width: isMobile ? "100%" : undefined,
          }}
        >
          <FormSearchSubmitButton isSheet={isSheet} navigateToSearchPage={navigateToSearchPage} onClick={onSubmit} />
        </Stack>
      </Stack>
    </>
  );
};

export default FormSearch;
