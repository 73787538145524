import numeral from "numeral";
import { getLocale } from "./getLocale";

// ----------------------------------------------------------------------

type InputValue = string | number | null;
export enum CurrencySymbolLocation {
  Left = "left",
  Right = "right",
}

const FILE_SIZE_UNITS: { [key in "az" | "en" | "ru"]: string }[] = [
  { az: "B", en: "B", ru: "Б" },
  { az: "KB", en: "KB", ru: "КБ" },
  { az: "MB", en: "MB", ru: "МБ" },
  { az: "GB", en: "GB", ru: "ГБ" },
  { az: "TB", en: "TB", ru: "ТБ" },
];

/**
 *
 */
export function fNumber(number: InputValue) {
  return numeral(number).format();
}

/**
 *
 */
export function fCurrency(
  number: InputValue,
  options: {
    locale?: "az" | "en" | "ru";
    currencySymbolLocation?: CurrencySymbolLocation;
  } = { locale: "en" }
) {
  const format = number != null ? numeral(number).format("0,0.00") : "";
  const value = result(format, ".00");

  return options.currencySymbolLocation === CurrencySymbolLocation.Left
    ? `₼${value}`
    : options.currencySymbolLocation === CurrencySymbolLocation.Right
    ? `${value}₼`
    : options.locale === "az"
    ? `${value}₼`
    : `₼${value}`;
}

/**
 *
 */
export function fPercent(number: InputValue) {
  const format = number ? numeral(Number(number) / 100).format("0.0%") : "";

  return result(format, ".0");
}

/**
 *
 */
export function fShortenNumber(number: InputValue) {
  const format = number ? numeral(number).format("0.00a") : "";

  return result(format, ".00");
}

/**
 * @param size file size in bytes
 */
export function fFileSize(size: number): { formattedSize: string; unit: string } {
  const locale = getLocale();
  if (size === 0) {
    return {
      formattedSize: "0",
      unit: FILE_SIZE_UNITS[0][locale],
    };
  }

  const unitMultiplier = 1024;
  const unitIdx = Math.floor(Math.log(size) / Math.log(unitMultiplier));
  let formattedSize = (size / Math.pow(unitMultiplier, unitIdx)).toFixed(1);
  formattedSize = formattedSize.endsWith(".0") ? formattedSize.slice(0, -2) : formattedSize;

  return {
    formattedSize: formattedSize,
    unit: FILE_SIZE_UNITS[unitIdx][locale],
  };
}

/**
 * @param size file size in bytes
 * @returns e.g. 12343 becomes 12.06 KB.
 */
export function fFileSizeToStr(size: number): string {
  const formattedFileSize = fFileSize(size);
  return `${formattedFileSize.formattedSize} ${formattedFileSize.unit}`;
}

/**
 *
 */
function result(format: string, key = ".00") {
  const isInteger = format.includes(key);

  return isInteger ? format.replace(key, "") : format;
}
